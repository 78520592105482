import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export default function Animations() {
	animations()
}


//
// Define your functions here
//
function animations() {

	gsap.registerPlugin(ScrollTrigger);
	gsap.registerPlugin(ScrollToPlugin);

	addClasses();
	//headerScrollUp();

	// Grab the prefers reduced media query
	const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

	if (mediaQuery && !mediaQuery.matches) { //if they have no preference/haven not chosen to reduce motion
		runAnimations();
		numberCounter();
	}

	// var formStep =  document.getElementById('formStep');

	// if(formStep && formStep.value > 1 ) {
	// 	gsap.to(window, {duration: 2, scrollTo: "#formStep"});
	// }
	
}

function addClasses() {

	// const header = document.querySelectorAll('header');
	// const main = document.querySelectorAll('main');
	// const footer = document.querySelectorAll('footer');

	// gsap.to(header, {
	// 	scrollTrigger: {
	// 		trigger: main,
	// 		endTrigger: footer,
	// 		start: 1,
	// 		end: "bottom top",
	// 		toggleClass: { targets: header, className: "scrolling" },
	// 		toggleActions: 'play none none none'
	// 	}
	// });


	//apply class is-active to all sections when they are in viewport
	const sections = document.querySelectorAll('section');

	sections.forEach((section) => {

		gsap.to(section, {
			scrollTrigger: {
				trigger: section,
				start: 'top bottom-=100px',
				end: () => `+=${section.clientHeight + 300}`,
				toggleActions: 'play reverse none none',
				toggleClass: { targets: section, className: "is-active" }
			}
		});


	});
}

function headerScrollUp() {
	const headerElement = document.querySelector('header');

	const showHeader = gsap.from(headerElement, { 
		yPercent: -100,
		paused: true,
		duration: 0.2,
	  }).progress(1);
	 
	   ScrollTrigger.create({
		start: 'top -50',
		end: 99999,
		onUpdate: ({direction, progress}) => {
		  if (direction == -1) {
			  showHeader.play();
			  headerElement.classList.add('scrolling');
			  headerElement.classList.remove('other-scroll');
		  } else {
			  showHeader.reverse();
			  headerElement.classList.add('other-scroll');
		  }
  
		  if (progress == 0) {
			  headerElement.classList.remove('scrolling');
			  headerElement.classList.remove('other-scroll');
		  }
		}
	});
}

function runAnimations() {
	// fade In section
	const sectionsFadeUp = document.querySelectorAll('.fadeUp');

	sectionsFadeUp.forEach((section) => {

		section.style.opacity = 0;

		gsap.to(section, {
			opacity: 1,
			scrollTrigger: {
				trigger: section,
				start: 'top bottom-=200',
				toggleActions: 'play none none reverse'
			}
		});

	});

	const sectionsSwipeIn = document.querySelectorAll('.swipeIn');

	sectionsSwipeIn.forEach((section) => {

		gsap.to(section, {
			once: true,
			scrollTrigger: {
				trigger: section,
				start: 'top bottom-=10',
				toggleActions: 'play none none reverse',
				toggleClass: { targets: section, className: "is-active" }
			},
		});
	});

	ScrollTrigger.batch(".swipeUp", {
		onEnter: elements => {
		  gsap.from(elements, {
			autoAlpha: 0,
			y: 60,
			stagger: 0.15,
			scrollTrigger: {
				trigger: elements,
				start: 'top bottom-=10'
			},
		  });
		},
		once: true
	  });

	  ScrollTrigger.batch(".swipeDown", {
		onEnter: elements => {
		  gsap.from(elements, {
			autoAlpha: 0,
			y: -100,
			stagger: 0.15,
			scrollTrigger: {
				trigger: elements,
				start: 'top bottom-=10'
			},
		  });
		},
		once: true
	  });


	const sections = document.querySelectorAll(".page");
	const scrolling = {
		enabled: true,
		events: "scroll,wheel,touchmove,pointermove".split(","),
		prevent: e => e.preventDefault(),
		disable() {
		  if (scrolling.enabled) {
			scrolling.enabled = false;
			window.addEventListener("scroll", gsap.ticker.tick, {passive: true});
			scrolling.events.forEach((e, i) => (i ? document : window).addEventListener(e, scrolling.prevent, {passive: false}));
		  }
		},
		enable() {
		  if (!scrolling.enabled) {
			scrolling.enabled = true;
			window.removeEventListener("scroll", gsap.ticker.tick);
			scrolling.events.forEach((e, i) => (i ? document : window).removeEventListener(e, scrolling.prevent));
		  }
		}
	  };
	
	
	function goToSection(section, anim, i) {
	  if (scrolling.enabled) { // skip if a scroll tween is in progress
		scrolling.disable();
		gsap.to(window, {
		  scrollTo: {y: section, autoKill: false},
		  onComplete: scrolling.enable,
		  duration: 1
		});
	
		anim && anim.restart();
	  }
	}
	
	sections.forEach((section, i) => {	  
	  ScrollTrigger.create({
		trigger: section,
		start: "top bottom-=1",
		end: "bottom top+=1",
		onEnter: () => goToSection(section),
		onEnterBack: () => goToSection(section)
	  });
	 
	});

}




function numberCounter() {
	const items = document.querySelectorAll(".final-number");
	gsap.from(items, {
		textContent: 0,
		duration: 4,
		snap: { textContent: 1 },
		stagger: 1,
		scrollTrigger: {
			trigger: items,
			start: 'top bottom-=10'
		},
	});
}