import { gsap } from 'gsap';

export default function Home() {
	home()
}

function home() {

    let overlay = document.querySelector('.modal-overlay');
    let modal = document.querySelector('.modal');

    document.getElementById("openModal").addEventListener("click", function (e) {
        overlay.classList.add('active');
        modal.classList.add('active');
    });

    document.getElementById('closeModal').addEventListener("click", function (e) {
        overlay.classList.remove('active');
        modal.classList.remove('active');
    });

    
    function scrollTrigger(selector, options = {}) {
        let els = document.querySelectorAll(selector)
        els = Array.from(els)
        els.forEach(el => {
            addObserver(el, options)
        })
    }

    function addObserver(el, options) {
        if (!('IntersectionObserver' in window)) {
            if (options.cb) {
                options.cb(el)
            } else {
                entry.target.classList.add('active')
            }
            return
        }
        let observer = new IntersectionObserver((entries, observer) => { //this takes a callback function which receives two arguments: the elemts list and the observer instance
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (options.cb) {
                        options.cb(el)
                    } else {
                        entry.target.classList.add('active')
                    }
                    observer.unobserve(entry.target)
                }
            })
        }, options)
        observer.observe(el)
    }
    // Example usages:

    scrollTrigger('.scroll-reveal', {
        rootMargin: '-200px',
    })

    // timeline to control animations everytime the timeline restarts/repeats
    let tlRepeat = gsap.timeline();

    // Need first slides elements - img and text to animate on each repeat of timeline
    let repeatBeginning = () => {
        gsap.set(bgImage[0], { opacity: 0, scale: 1.2, webkitFilter: 'blur(' + 6 + 'px)' })

        tlRepeat
            .add('slide1-in')
            .fromTo([countTop[0], countBottom[0]], { opacity: 0 }, { duration: 0.3, opacity: 1, ease: 'Power2.easeIn' }, 'slide1-in')
            .to(bgImage[0], { duration: 1.8, scale: 1, opacity: 1, webkitFilter: 'blur(' + 0 + 'px)' }, 'slide1-in')
            .fromTo(text[0], { opacity: 0, x: -30, ease: 'Power2.easeIn' }, { duration: 0.8, opacity: 1, x: 0 }, '-=1')


    }

    // On start animations
    // let onStartSlide1Animations = ()=>{
    //     // gsap.to(text[0], {duration: 0.7, opacity: 1, x: -15, ease: 'Power2.easeIn'})
    // }

    // Variables
    let slides = document.querySelectorAll('.slider__slide'),
        dynamicBar = document.querySelector('#sliderBarDynamic'),
        countTop = document.querySelectorAll('.count--top'),
        countBottom = document.querySelectorAll('.count--bottom'),
        bgImage = document.querySelectorAll('.slider__img'),
        text = document.querySelectorAll('.slider__text'),
        tl = gsap.timeline({ repeat: -1, delay: 1, paused: false, onRepeat: repeatBeginning });

    // Push all text back and only make first one visible
    gsap.set(text, { x: -30 });
    gsap.set(text[0], { opacity: 1 });


    // Animate slide's elements but not the first one. 
    // Make first slide's elements animate when timeline is repeating, 
    // Follow the flow of rest of the slide's animations
    slides.forEach((slide, i) => {

        tl
            .fromTo(dynamicBar, { scaleY: 0 }, { duration: 1.4, scaleY: 1 }, '+=2')
            .set(dynamicBar, { transformOrigin: 'bottom center' })
            .to(dynamicBar, { duration: 1, scaleY: 0 }, '+=0.4')
            .set(dynamicBar, { transformOrigin: 'top center' })
            .add('elements-in-out')
            .to([countTop[i], countBottom[i]], { opacity: 0 }, 'elements-in-out')
            .to([countTop[i + 1], countBottom[i + 1]], { opacity: 1 }, 'elements-in-out')
            .to(bgImage[i], { duration: 0.2, opacity: 0 }, 'elements-in-out')
            .set(bgImage[i + 1], { scale: 1.2, webkitFilter: 'blur(' + 6 + 'px)' }, 'elements-in-out')
            .to(bgImage[i + 1], { duration: 1.8, scale: 1, webkitFilter: 'blur(' + 0 + 'px)' }, 'elements-in-out')
            .to(text[i], { duration: 0.3, opacity: 0 }, 'elements-in-out')
            .to(text[i + 1], { duration: 0.8, opacity: 1, x: 0 }, '-=1')
    });
}
